import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../context/user-context';
import {getDate, getDateAndTime, packagesNamesWithIntervals} from '../../helpers';
import {Modal} from '../../components/modal';
import {getUser, paymentCancelResume} from '../../requests';
import CustomProgress from '../custom-progress';
import './styles.scss';
import {Link, navigate} from 'gatsby';

export default ({}) => {
  const {user, setUser} = useContext(UserContext);
  const [confirmationPopup, setConfirmationPopup] = useState({
    show: false,
    type: '',
  });
  const [loading, setLoading] = useState(false);

  const getUserInfo = async () => {
    const userInfo = await getUser();
    userInfo && setUser(userInfo);
    if (userInfo && !userInfo.id) {
      navigate('/');
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const handleCancel = async () => {
    await paymentCancelResume(user.package_data.product_type, true);

    setConfirmationPopup({show: false, type: ''});
    getUserInfo();
  };
  const handleResume = async () => {
    const resp = await paymentCancelResume(user.package_data.product_type, false);

    setConfirmationPopup({show: false, type: ''});
    getUserInfo();
  };

  return user && user.id ? (
    <div className="profile-card row position-relative">
      <div className="left-section col-lg-5">
        <div className="d-block d-lg-flex m-auto">
          <img src={user.picture} alt={user.name} className="" />
        </div>
        <div className={`name-info`}>
          <div className="title">{user.name}</div>
          <div>{user.email}</div>
        </div>
      </div>
      <div className="col-lg-7 right-section my-auto">
        <div className="progress-container mt-2 d-flex flex-wrap">
          <div className="text-left mr-2">Registration date:</div>
          <div style={{textTransform: 'capitalize'}}>{getDateAndTime(user.created_at)}</div>
        </div>
        {user.package_data && typeof user.package_data.limit === 'number' && typeof user.request_left === 'number' && (
          <>
            {user.package_data.name && (
              <div className="progress-container mt-2 d-flex flex-wrap">
                <div className="text-left mr-2">Current plan:</div>
                <div style={{textTransform: 'capitalize'}} className="mr-2">
                  {user.package_data.name.toLowerCase() === 'free'
                    ? 'Free'
                    : packagesNamesWithIntervals[user.package_data.name.replace('_OLD', '')]}
                </div>
                <div onClick={() => user?.id ? navigate('/pricing') : navigate('/#pricing')} className="clickable-text-bold">
                  Upgrade
                </div>
              </div>
            )}
            <div className="progress-container mt-2 d-flex flex-wrap">
              <div className="text-left mr-2">Results scraped:</div>
              {user.package_data.limit === 500 ? (
                user.package_data.limit - user.request_left
              ) : (
                <CustomProgress
                  percent={
                    user.request_left > user.package_data.limit ||
                    user.request_left <= 0
                      ? 100
                      : ((user.package_data.limit - user.request_left) / user.package_data.limit) * 100 || 0
                  }
                  text={`${
                    user.request_left > user.package_data.limit || user.request_left <= 0
                      ? 100
                      : Math.round(((user.package_data.limit - user.request_left) / user.package_data.limit) * 100)
                  }% used out of 100%`}
                />
              )}
            </div>
          </>
        )}
        {user.package_data.name !== 'LTD' && user.package_data.name !== 'free' && (
          <div className="d-flex cancel-section">
            {!user.cancel_at ? (
              <span
                className="ml-auto clickable-text"
                onClick={() =>
                  setConfirmationPopup({
                    show: true,
                    type: 'cancel',
                  })
                }
              >
                Cancel subscription renewal
              </span>
            ) : (
              <div className="ml-auto">
                <span>Your subscription will be canceled on {getDate(user.cancel_at)}.</span>{' '}
                {user.package_data.product_type === 'stripe' && (
                  <span
                    className="clickable-text"
                    onClick={() =>
                      setConfirmationPopup({
                        show: true,
                        type: 'resume',
                      })
                    }
                  >
                    Resume subscription
                  </span>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {confirmationPopup && confirmationPopup.show && (
        <Modal
          isOpen={confirmationPopup.show}
          modalToggle={() => setConfirmationPopup({show: false, type: ''})}
          title={
            confirmationPopup.type === 'cancel'
              ? 'Please confirm that you want to cancel your subscription renewal.'
              : 'Please confirm that you want to resume your subscription renewal.'
          }
          onButtonClick={() => (confirmationPopup.type === 'cancel' ? handleCancel() : handleResume())}
          buttonText={
            loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              'Confirm'
            )
          }
          danger
        />
      )}
    </div>
  ) : (
    <></>
  );
};
