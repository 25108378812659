import React, {FC, memo, useState} from 'react';
import Pagination from '../../components/pagination';
import Button from '../button';
import {getDateAndTime, handleDownloadFile} from '../../helpers';
import './styles.scss';
import {downloadIcon} from '../../img/svg-icons';

const options = [5, 15, 25, 30, 50];

const HistoryTable: FC<any> = ({list, total, sort, setSort, page, setPage, limit, setLimit}) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleRowOptionClick = (val: any) => {
    setLimit(val);
    setPage(1);
    setShowMenu(false);
  };

  const handleChangeSortByDate = () => {
    setSort({sortBy: 'scanned_at', type: sort.type === 'desc' && sort.sortBy === 'scanned_at' ? 'asc' : 'desc'});
  };

  const handleChangeSortBySource = () => {
    setSort({sortBy: 'source', type: sort.type === 'desc' && sort.sortBy === 'source' ? 'asc' : 'desc'});
  };

  const handleChangePage = (val: any) => {
    setPage(val);
  };

  return (
    <>
      <div className=" w-100 history_table mx-auto">
        <div className="row w-100 table-header">
          <div className={`primary-text col-md-4 text-left`} onClick={handleChangeSortBySource}>
            Keyword
            <span className={`sorting-arrow ${sort.sortBy === 'source' ? 'active' : ''}`}>
              {sort.type === 'desc' ? '↓' : '↑'}
            </span>
          </div>
          <div className={`primary-text col-md-2 text-left`} onClick={handleChangeSortBySource}>
            Tool name
            <span className={`sorting-arrow ${sort.sortBy === 'source' ? 'active' : ''}`}>
              {sort.type === 'desc' ? '↓' : '↑'}
            </span>
          </div>
          <div className={`primary-text col-md-3 text-left`} onClick={handleChangeSortByDate}>
            Scan date
            <span className={`sorting-arrow ${sort.sortBy === 'scanned_at' ? 'active' : ''}`}>
              {sort.type === 'desc' ? '↓' : '↑'}
            </span>
          </div>

          <div className="primary-text col-md-3"></div>
        </div>
        {list.map((item: any) => (
          <div className="cursor-pointer row w-100 table-body mb-2" key={item.id}>
            <div className={`secondary-text text-truncate col-sm-6 col-lg-4 text-left m-auto`}>{item.keyword}</div>
            <div className={`secondary-text col-sm-6 col-lg-2 text-sm-lg-right text-left m-auto`}>{item.toolIcon} {item.toolName}</div>
            <div className={`secondary-text col-sm-6 col-lg-3 text-sm-lg-right text-left m-auto`}>
              {getDateAndTime(item.scanned_at)}
            </div>
            <div className="col-lg-3 text-right">
              <div className='d-flex'>
                <Button onClick={() => handleDownloadFile(item.csv)} className="mr-2" outline>
                {downloadIcon} CSV
                </Button>
                <Button onClick={() => handleDownloadFile(item.json)} className="" outline>
                 {downloadIcon} JSON 
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="table-footer">
        <div className="table-footer-left d-flex">
          <span className="row-num-lable">Rows per page</span>
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" onClick={() => setShowMenu(!showMenu)}>
              {limit}
            </button>
            {showMenu && (
              <div className="dropdown-menu">
                {options.map(option => (
                  <div key={option} className="dropdown-item" onClick={() => handleRowOptionClick(option)}>
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {Math.ceil(total / limit) > 1 && (
          <div className="table-footer-right">
            <Pagination
              activePage={page}
              onChange={handleChangePage}
              totalCount={Math.ceil(total / limit)}
              itemsPerPage={1}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default memo(HistoryTable);
